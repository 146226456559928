.div100 {
	height: 100px;
}

.div70 {
	height: 70px;
}

.div80 {
	height: 80px;
}

.div55 {
	height: 55px;
}

.div30 {
	height: 30px;
}

.div40 {
	height: 40px;
}

.div45 {
	height: 45px;
}

.div50 {
	height: 50px;
}

.div140 {
	height: 140px;
}

.div60 {
	height: 60px;
}

.mr5{
	margin-right: 5px;
}

/* 设置滚动条的样式 */
::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset006pxrgba(211, 211, 211, 1.0);
	border-radius: 2px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background: rgba(211, 211, 211, 1.0);
	-webkit-box-shadow: inset006pxrgba(211, 211, 211, 1.0);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: rgba(0, 0, 0, 0.1);
}