@media (min-width: 769px) {
	.sastView {
		width: 100%;
		background: #000;
	}
	
	.sastVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.sastVideo::before,
	.sastVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sastVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.sastContent {
		margin: auto;
		width: 100%;
		padding-top: 30vh;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.sastTitle {
		background: linear-gradient(180deg, #ffffff 0%, #62c67e 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 35px;
		width: 620px;
		margin: auto;
		text-align: center;
		font-family: DingTalkJinBuTi;
		font-weight: 100;
		letter-spacing: 1px;
		padding-bottom: 35px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.sastTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #62c67e 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-size: 20px;
		margin-top: 35px;
		letter-spacing: 2px;
		font-family: DingTalkJinBuTi;
	}
	.sastTitle3 {
		width: 600px;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}
	
	.sastBtn {
		margin-top: 35px;
		border: 0.1px solid rgba(58, 170, 62, 0.6);
		box-shadow: 0px 1px 15px 1px rgba(154, 255, 126, 0.7);
		padding: 12px 0 12px 0;
		font-size: 17px;
		font-weight: 100;
		border-radius: 3px;
		font-family: T3;
	}
	
	.sastTitleBtn {
		width: 200px;
		margin: 50px auto;
		background: rgba(20, 154, 67, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(20, 154, 67, 0.8);
		/* background: rgba(138, 138, 138, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(138, 138, 138, 0.8); */
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 3px;
		cursor: pointer;
		color: #e8e8e8;
		font-family: T3;
	}
	
	.sastTitleBtn:hover {
		background: rgba(20, 154, 67, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(20, 154, 67, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.sastCon2 {
		width: 1280px;
		margin: auto;
		padding-top: 30vh;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.sastCon2Phone {
		display: none;
	}
	
	.sast2Card1 {
		margin-top: 80px;
	}
	
	.sast2cImgBg {
		width: 100%;
		border-radius: 20px;
	}
	
	.sast2cImgBg::before,
	.sast2cImgBg::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sast2cImgBg::before {
		top: 0;
		left: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sast2cImgBg::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sast2cImg {
		width: 100%;
		opacity: 0.5;
	}
	
	.sast2cView {
		width: 370px;
		display: inline-block;
	}
	
	.sast2cText1 {
		color: #fff;
		font-size: 20px;
		letter-spacing: 2px;
		margin-top: 120px;
		padding-bottom: 30px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.sast2cText2 {
		margin-top: 30px;
		text-align: left;
		line-height: 30px;
		letter-spacing: 2px;
		font-size: 16px;
		color: #fff;
		font-family: T1;
	}
	
	.sastCon3 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 100px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.sastCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 155px;
	}
	
	.sastCardCon::before,
	.sastCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 50px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sastCardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastCardImg{
		width: 100%;
		position: relative;
		top: 0;
		opacity: 0.7;
	}
	
	.sastc3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.sast3Div{
	}
	
	.sast3Div1{
		width: 300px;
		height: 300px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		margin-right: 25px;
		margin-top: 30px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(20, 154, 67, 0.8);
	}
	
	.sast3Div2{
		width: 300px;
		height: 300px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		margin-top: 30px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		margin-left: 25px;
		box-shadow: 0px 1px 30px 5px rgba(20, 154, 67, 0.8);
	}
	
	
	.sast3Div3{
		width: 300px;
		height: 300px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		margin-top: 50px;
		margin-right: 25px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(20, 154, 67, 0.8);
	}
	
	
	.sast3Div4{
		width: 300px;
		height: 300px;
		border-radius: 50%;
		display: inline-block;
		position: relative;
		margin-top: 50px;
		background:rgba(0, 0, 0, 0.3);
		margin-left: 25px;
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(20, 154, 67, 0.8);
	}
	
	.sast3cTitle{
		background: linear-gradient(90deg, #b7ffbd 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(20, 154, 67, 0.3);
		font-family: T3;
	}
	
	.sast3cImg{
		width: 300px;
		height: 70px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(67, 67, 67, 0.1) 0%, #000000 10%);
	}
	
	.sastImgS{
		width: 60px;
		margin-top: 5px;
		height: 60px;
		opacity: 0.6;
	}
	
	.sast3cText1{
		margin-top: 10px;
		padding: 20px;
		letter-spacing: 1px;
		line-height: 27px;
		font-size: 15px;
		color: #fff;
		font-family: T1;
	}
}
@media screen and (max-width: 769px) {
	.sastView {
		width: 100%;
		background: #000;
		overflow: hidden;
	}
	
	.sastVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.sastVideo::before,
	.sastVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sastVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.sastContent {
		margin: auto;
		width: 100%;
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.sastTitle {
		background: linear-gradient(180deg, #ffffff 0%, #62c67e 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		width: 100%;
		margin: auto;
		text-align: center;
		font-family: DingTalkJinBuTi;
		font-weight: 100;
		letter-spacing: 1px;
		padding-bottom: 35px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.sastTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #62c67e 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-size: 16px;
		margin-top: 20px;
		letter-spacing: 2px;
		font-family: DingTalkJinBuTi;
	}
	.sastTitle3 {
		width: 95%;
		display: inline-block;
		margin-top: 10px;
		text-align: center;
		color: #ffffff;
	}
	
	.sastBtn {
		margin-top: 20px;
		border: 0.1px solid rgba(58, 170, 62, 0.6);
		box-shadow: 0px 1px 15px 1px rgba(154, 255, 126, 0.7);
		padding: 8px 0 8px 0;
		font-size: 13px;
		font-weight: 100;
		border-radius: 3px;
		font-family: T3;
	}
	
	.sastTitleBtn {
		width: 200px;
		margin: 30px auto;
		background: rgba(20, 154, 67, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(20, 154, 67, 0.8);
/* 		background: rgba(138, 138, 138, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(138, 138, 138, 0.8); */
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 3px;
		cursor: pointer;
		font-size: 13px;
		color: #e8e8e8;
		font-family: T3;
	}
	
	.sastTitleBtn:hover {
		background: rgba(20, 154, 67, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(20, 154, 67, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.sastCon2 {
		display: none;
	}
	
	.sastCon2Phone {
		width: 100%;
	}
	
	.sast2Card1 {
		margin-top: 50px;
	}
	
	.sast2cView {
		width: 80%;
		display: inline-block;
	}
	
	.sast2cText1 {
		color: #fff;
		font-size: 16px;
		letter-spacing: 2px;
		margin-top: 30px;
		padding-bottom: 20px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.sast2cText2 {
		margin-top: 20px;
		text-align: left;
		line-height: 30px;
		letter-spacing: 2px;
		font-size: 15px;
		color: #fff;
		font-family: T1;
	}
	
	.sastCon3 {
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 100px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.sastCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 155px;
	}
	
	.sastCardCon::before,
	.sastCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 50px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sastCardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sastCardImg{
		width: 100%;
		position: relative;
		top: 0;
		opacity: 0.7;
	}
	
	.sastc3Title {
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.sast3Div{
		width: 90%;
		display: inline-block;
	}
	
	.sast3Div1{
		display: inline-block;
		position: relative;
		margin-top: 30px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 5px 5px rgba(20, 154, 67, 0.5);
	}
	
	.sast3Div2{
		display: inline-block;
		position: relative;
		margin-top: 30px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 5px 5px rgba(20, 154, 67, 0.5);
	}
	
	
	.sast3Div3{
		display: inline-block;
		position: relative;
		margin-top: 50px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 5px 5px rgba(20, 154, 67, 0.5);
	}
	
	
	.sast3Div4{
		display: inline-block;
		position: relative;
		margin-top: 50px;
		background:rgba(0, 0, 0, 0.3);
		overflow: hidden;
		box-shadow: 0px 1px 5px 5px rgba(20, 154, 67, 0.5);
	}
	
	.sast3cTitle{
		background: linear-gradient(90deg, #b7ffbd 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(20, 154, 67, 0.3);
		font-family: T3;
	}
	
	.sast3cImg{
		display: none;
	}
	
	.sastImgS{
		width: 60px;
		margin-top: 5px;
		height: 60px;
		opacity: 0.6;
	}
	
	.sast3cText1{
		margin-top: 10px;
		padding: 20px;
		letter-spacing: 1px;
		line-height: 27px;
		font-size: 15px;
		color: #fff;
		font-family: T1;
	}
}