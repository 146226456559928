@media (min-width: 769px) {
	.aboutView {
		width: 100%;
		background: #000;
	}
	
	.aboutVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.aboutBg{
		width: 100%;
	}
	
	.aboutVideo::before,
	.aboutVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.aboutVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.aboutContent {
		margin: auto;
		width: 100%;
		padding-top: 30vh;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.aboutTitle {
		background: linear-gradient(180deg, #ffffff 0%, #f8ceff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 35px;
		width: 100%;
		margin: auto;
		text-align: center;
		font-weight: 100;
		letter-spacing: 1px;
		padding-bottom: 35px;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutCon2 {
		width: 1280px;
		margin: auto;
		padding-top: 30vh;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.about2Card1 {
		margin-top: 80px;
	}
	
	.about2cImgBg {
		width: 100%;
		opacity: 0.5;
		margin-top: 15px;
		border-radius: 20px;
	}
	
	.about2cImgBg::before,
	.about2cImgBg::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.about2cImgBg::before {
		top: 0;
		left: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.0) 90%, rgba(0, 0, 0, 0.9) 100%);
		z-index: 1;
	}
	
	.about2cImgBg::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.0) 70%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.about2cImg {
		width: 100%;
	}
	
	.about2cView {
		width: 520px;
		display: inline-block;
	}
	
	.about2cText1 {
		color: #e8e8e8;
		font-size: 20px;
		letter-spacing: 2px;
		margin-top: 10px;
		padding-bottom: 30px;
		font-family: DingTalkJinBuTi;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
	
	}
	
	.about2cText2 {
		margin-top: 30px;
		text-align: left;
		line-height: 28px;
		letter-spacing: 2px;
		color: #ffffff;
		font-size: 14px;
		font-family: T1;
		text-indent: 2.3em;
	}
	
	.about2cText3 {
		margin-top: 5px;
		text-align: left;
		line-height: 28px;
		letter-spacing: 2px;
		color: #ffffff;
		font-size: 14px;
		font-family: T1;
		text-indent: 2.3em;
	}
	
	.aboutCon3 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 180px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.aboutCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 200px;
	}
	
	.aboutCardCon::before,
	.aboutCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 0px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.aboutCardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutCardImg{
		width: 100%;
		position: relative;
		top: 0;
		opacity: 0.7;
	}
	
	.aboutc3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.about3cTitle{
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
	}
	
	.aboutWapper{
		display: flex;
		margin-top: 30px;
	}
	
	.aboutWleft{
		width: 500px;
		height: 500px;
		text-align: center;
	}
	
	.aboutWright{
		width: calc(100% - 500px);
		height: 500px;
		text-align: center;
	}
	
	.about-ld{
		width: 500px;
		height: 500px;
		display: inline-block;
		text-align: center;
	}
	
	.about-ld img{
		width: 320px;
		margin-left: 90px;
		text-align: center;
		height: 450px;
	}
	
	.about-rd{
		width: 500px;
		height: 500px;
		display: inline-block;
		text-align: center;
	}
	
	.about-rd img{
		width: auto;
		margin-left: 90px;
		text-align: center;
		height: 450px;
	}
	
	.aboutCon4{
		margin-top: 100px;
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 180px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.aboutCon4Phone{
		display: none;
	}
	
	.aboutc4Title{
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutlxLeft{
		width:380px;
		margin-top: 50px;
		display: inline-block;
	}
	
	.aboutlxRight{
		width:380px;
		margin-top: 50px;
		display: inline-block;
	}
	.aboutlx1{
		text-align: left;
		color: #d5d5d5;
		font-size: 20px;
		padding-bottom: 20px;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutlx2{
		color: #ffffff;
		text-align: left;
		padding-bottom: 15px;
		font-size: 16px;
		font-family: T1;
	}
	
	.aboutlx3{
		color: #ffffff;
		text-align: left;
		font-size: 16px;
		font-family: T1;
	}
	
	.aboutIcon{
		margin-right: 5px;
	}
}
@media screen and (max-width: 769px) {
	.aboutView {
		width: 100%;
		background: #000;
		overflow: hidden;
	}
	
	.aboutVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.aboutBg{
		width: 100%;
	}
	
	.aboutVideo::before,
	.aboutVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.aboutVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.aboutVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.aboutContent {
		margin: auto;
		width: 100%;
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.aboutTitle {
		background: linear-gradient(180deg, #ffffff 0%, #f8ceff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 18px;
		width: 100%;
		margin: auto;
		text-align: center;
		font-weight: 100;
		letter-spacing: 1px;
		padding-bottom: 35px;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutCon2 {
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.about2Card1 {
		margin-top: 80px;
	}
	
	.about2cImgBg {
		width: 100%;
		opacity: 0.5;
		border-radius: 20px;
		display: none;
	}

	.about2cView {
		width: 190%;
		position: relative;
		left: 10%;
		display: inline-block;
	}
	
	.about2cText1 {
		color: #e8e8e8;
		font-size: 18px;
		letter-spacing: 2px;
		margin-top: 10px;
		padding-bottom: 20px;
		font-family: DingTalkJinBuTi;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
	
	}
	
	.about2cText2 {
		margin-top: 20px;
		text-align: left;
		line-height: 28px;
		letter-spacing: 2px;
		color: #ffffff;
		font-size: 14px;
		font-family: T1;
	}
	
	.aboutCon3 {
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 50px;
		overflow: hidden;
	}
	
	.aboutCardCon{
		display: none;
	}

	.aboutc3Title {
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.about3cTitle{
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
	}
	
	.aboutWapper{
		margin-top: 30px;
	}
	
	.aboutWleft{
		width: 100%;
		text-align: center;
	}
	
	.aboutWright{
		width: 100%;
		text-align: center;
	}
	.about-ld{
		width: 100%;
		height: 400px;
		display: inline-block;
		text-align: center;
	}
	
	.about-ld img{
		width: auto;
		margin-left: 50px;
		text-align: center;
		height: 400px;
	}
	
	.about-rd{
		width: 100%;
		display: inline-block;
		text-align: center;
		margin-top: 50px;
	}
	
	.about-rd img{
		width: 90%;
		text-align: center;
		margin-left: 5%;
		height: auto;
		margin-bottom: 20px;
	}
	
	.aboutCon4{
		display: none;
	}
	
	.aboutCon4Phone{
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 50px;
		overflow: hidden;
	}
	
	.aboutc4Title{
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutlxLeft{
		width:90%;
		margin-top: 20px;
		display: inline-block;
	}
	
	.aboutlxRight{
		width:90%;
		margin-top: 50px;
		display: inline-block;
	}
	.aboutlx1{
		text-align: left;
		color: #d5d5d5;
		font-size: 16px;
		padding-bottom: 20px;
		font-family: DingTalkJinBuTi;
	}
	
	.aboutlx2{
		color: #ffffff;
		text-align: left;
		padding-bottom: 15px;
		font-size: 14px;
		font-family: T1;
	}
	
	.aboutlx3{
		color: #ffffff;
		text-align: left;
		font-size: 14px;
		font-family: T1;
	}
	
	.aboutIcon{
		margin-right: 5px;
	}
}
