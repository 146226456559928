@media (min-width: 769px) {
	.bottomBg{
		padding: 20px 20px 0 20px;
		background: #000c17;
		text-align: center;
	}
	
	.bottomView{
		width: 1280px;
		display: inline-block;
		text-align: center;
	}
	
	.bottomNav{
		width: 800px;
		display: inline-block;
		text-align: center;
	}
	
	.bottomnItem{
		color: #ffffff;
		cursor: pointer;
	}
	
	.bottomCon{
		margin: 30px 0 30px 0;
	}
	
	.bottomConPhone{
		display: none;
	}
	
	.bottomcText1{
		font-size: 20px;
		color: #ffffff;
		font-weight: 500;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		left: 20%;
		font-family: T3;
	}
	
	.bottomcText2{
		font-size: 15px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 10px;
		left: 20%;
		font-family: T3;
	}
	
	
	.bottomcText3{
		font-size: 14px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 30px;
		left: 20%;
		font-family: T1;
	}
	
	.bottomcText4{
		font-size: 14px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 12px;
		left: 20%;
		font-family: T1;
	}
	
	.bottomcText5{
		font-size: 13px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 30px;
		left: 20%;
		font-family: T3;
	}
	.bottomcText6{
		font-size: 12px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 12px;
		left: 20%;
		font-family: T1;
	}
	.bottomcText7{
		font-size: 13px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 20px;
		left: 20%;
		font-family: T3;
	}
	.bottomcText8{
		font-size: 12px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		position: relative;
		margin-top: 12px;
		left: 20%;
		font-family: T1;
	}
	
	.bottomcIcon1{
		font-size: 20px;
		color: #ffffff;
		font-weight: 500;
		letter-spacing: 1px;
		text-align: center;
		position: relative;
		font-family: T3;
	}
	
	.bottomcIcon2{
		font-size: 15px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: center;
		position: relative;
		font-family: T3;
		margin-top: 10px;
	}
	
	.bottomcIcon3{
		position: relative;
		width: 200px;
		margin-top: 30px;
		font-family: T1;
		overflow: hidden;
		display: inline-flex;
	}
	
	.bottomImgV1{
		width: 90px;
	}
	
	.bottomImgV2{
		width: 90px;
		margin-left: 20px;
	}
	
	.bottomqrimgs{
		width: 90px;
		height: 90px;
	}
	
	.bottomqrText{
		color: #ffffff;
		margin-top: 10px;
		font-size: 13px;
		letter-spacing: 1px;
	}
	
	.bottomOteher1{
		font-size: 20px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 2px;
	}
	
	.bottomOteher2{
		font-size: 20px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 2px;
		margin-top: 20px;
	}
	
	.bottomOteher3{
		font-size: 20px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 2px;
		margin-top: 20px;
	}
	
	.bottomOteher4{
		width: 150px;
		margin-top: 50px;
		display: inline-block;
	}
	
	.bottomOthBtn{
		color: #ffffff;
		font-size: 23px;
	}
	
	.bottomIcp{
		padding: 30px 0 30px 0;
		font-size: 13px;
		border-top: 1px solid rgba(229, 242, 255, 0.3);
		color: #e8e8e8;
		font-family: T3;
	}
}

@media screen and (max-width: 769px) {
	.bottomBg{
		padding: 10px 10px 0 10px;
		background: #000c17;
		text-align: center;
	}
	
	.bottomView{
		width: 100%;
		display: inline-block;
		text-align: center;
		overflow: hidden;
	}
	
	.bottomNav{
		width: 100%;
		display: inline-block;
		text-align: center;
	}
	
	.bottomnItem{
		color: #ffffff;
		cursor: pointer;
	}
	
	.bottomCon{
		display: none;
	}
	
	.bottomConPhone{
		width: 100%;
	}
	
	.bottomcIcon3{
		margin-top: 10px;
	}

	.bottomqrimgs{
		width: 50%;
		height: auto;
		margin-top: 20px;
	}
	
	.bottomqrText{
		color: #fff;
		font-family: T3;
		font-size: 16px;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	
	.bottomcText2{
		color: #fff;
		font-size: 20px;
		font-family: T3;
		margin-top: 10px;
		margin-bottom: 10px;
		border-top: 1px solid rgba(229, 242, 255, 0.2);
		padding-top: 50px;
	}
	.bottomcText3{
		color: #fff;
		font-size: 16px;
		font-family: T1;
		margin-top: 15px;
	}
	.bottomcText4{
		color: #fff;
		font-family: T1;
		margin-top: 15px;
		margin-bottom: 50px;
	}
	
	.bottomcText5{
		font-size: 13px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: center;
		position: relative;
		margin-top: 30px;
		font-family: T3;
	}
	.bottomcText6{
		font-size: 12px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: left;
		margin-top: 12px;
		text-align: center;
		font-family: T1;
	}
	.bottomcText7{
		font-size: 13px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: center;
		margin-top: 20px;
		font-family: T3;
	}
	.bottomcText8{
		font-size: 12px;
		color: #ffffff;
		font-weight: 100;
		letter-spacing: 1px;
		text-align: center;
		position: relative;
		margin-top: 12px;
		font-family: T1;
	}
	
	.bottomOteher4{
		width: 100%;
		margin-top: 50px;
		border-top: 1px solid rgba(229, 242, 255, 0.2);
		padding-top: 50px;
		border-bottom: 1px solid rgba(229, 242, 255, 0.2);
		padding-bottom:50px;
	}
	
	.bottomOthBtn{
		color: #fff;
		font-size: 25px;
	}
	.bottomIcp{
		padding: 20px 0 20px 0;
		font-size: 12px;
		border-top: 1px solid rgba(229, 242, 255, 0.3);
		color: #e8e8e8;
		font-family: T1;
	}
}
