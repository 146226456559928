html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
margin: 0;
padding: 0;
border: 0;
outline: 0;
font-size: 100%;
vertical-align: baseline;
background: transparent;
}

@font-face {
	font-family: DingTalkJinBuTi;
	src: url('./font/DingTalk-JinBuTi.ttf');
}
@font-face {
	font-family: T1;
	src: url('./font/TsangerYuYangT_W01_W01.ttf');
}
@font-face {
	font-family: T3;
	src: url('./font/TsangerYuYangT_W03_W03.ttf');
}
body {
line-height: 1;
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: ”;
content: none;
}
/* remember to define focus styles! */
:focus {
outline: 0;
}
/* remember to highlight inserts somehow! */
ins {
text-decoration: none;
}
del {
text-decoration: line-through;
}
/* tables still need ‘cellspacing=”0″‘ in the markup */
table {
border-collapse: collapse;
border-spacing: 0;
}

@media (min-width: 769px) {
	.modelView{
		width: 100%;
		text-align: center;
	}
	.modelTitle{
		font-family: T3;
		font-size: 20px;
		color: #080808;
	}
	
	.modelTitle2{
		font-family: T3;
		font-size: 18px;
		color: #080808;
	}
	
	.modelIcon3{
		position: relative;
		width: 100%;
		margin-top: 30px;
		font-family: T1;
		overflow: hidden;
		display: inline-flex;
	}
	
	.modelqrimgs{
		width: 200px;
		height: 200px;
	}
	
	.modelText{
		font-family: T3;
		font-size: 15px;
		color: #000000;
	}
	
	.modelImgV1{
		width: 50%;
		text-align: center;
	}
}

@media screen and (max-width: 769px) {
	.modelView{
		width: 100%;
		text-align: center;
	}
	.modelTitle{
		font-family: T3;
		font-size: 20px;
		color: #080808;
	}
	
	.modelTitle2{
		font-family: T3;
		font-size: 18px;
		color: #080808;
	}
	
	.modelIcon3{
		position: relative;
		width: 100%;
		margin-top: 30px;
		font-family: T1;
		overflow: hidden;
		display: inline-flex;
	}
	
	.modelqrimgs{
		width: 120px;
		height: 120px;
	}
	
	.modelText{
		font-family: T3;
		font-size: 15px;
		color: #000000;
	}
	
	.modelImgV1{
		width: 50%;
		text-align: center;
	}
}