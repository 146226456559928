@media (min-width: 769px) {
	.trendsView {
		width: 100%;
		background: #000000;
	
	}
	
	.trendsCon1{
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		min-height: calc(100vh - 70px);
		padding-bottom: 150px;
		text-align: center;
		z-index: 3;
	}
	
	.trendsTitle {
		background: linear-gradient(180deg, #d2eaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 35px;
		width: 920px;
		padding-top: 80px;
		margin: auto;
		font-weight: bold;
		font-family: DingTalkJinBuTi;
		text-align: center;
		letter-spacing: 2px;
		padding-bottom: 35px;
	}
	
	
	.trendsVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.trendsBg{
		width: 100%;
		opacity: 0.2;
	}
	
	.trendsVideo::before,
	.trendsVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.trendsVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.trendsVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.trend1Table{
		width: 1000px;
		padding: 30px;
		display: inline-block;
	}
	
	.trend1Item{
		display: inline-flex;
		align-items: center;
		width: 100%;
		margin-bottom: 30px;
		position: relative;
	}
	
	.trend1Left{
		width: 75%;
		letter-spacing: 2px;
		text-align: left;
		padding-bottom: 30px;
		padding: 30px 0 30px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	}
	
	.trend1Right{
		width: 15%;
		margin-left: 5%;
		text-align: right;
		font-size: 18px;
		font-weight: 100;
		height: 100%;
		transform:translate(0,46%);
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		position: absolute;
		right: 0;
	}
	
	.trend1Right::before{
		content: '';
		position: absolute;
		width: 15px;
		border-radius: 50%;
		height: 15px;
		background: rgba(192, 226, 255, 1.0);
		box-shadow: 0px 1px 5px 3px rgba(255, 255, 255, 0.7);
		left: 10px;
	}
	
	.trend1Right::after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 17.5px;
		top: 23px;
		border-left: 1px dashed #c9e2ff;
	}
	
	.newsTitle{
		font-weight: bold;
		color: #e8e8e8;
		font-size: 16px;
		font-family: T3;
	}
	
	
	.trend1RightEnd{
		width: 15%;
		margin-left: 5%;
		text-align: right;
		font-size: 18px;
		font-weight: 100;
		height: 100%;
		transform:translate(0,46%);
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		position: absolute;
		right: 0;
	}
	
	.trend1RightEnd::before{
		content: '';
		position: absolute;
		width: 15px;
		border-radius: 50%;
		height: 15px;
		background: rgba(192, 226, 255, 1.0);
		box-shadow: 0px 1px 5px 3px rgba(255, 255, 255, 0.7);
		left: 10px;
	}
	
	.newsCon{
		font-size: 16px;
		margin-top: 20px;
		line-height: 25px;
		color: #ffffff;
		font-family: T1;
	}
}
@media screen and (max-width: 769px) {
	.trendsView {
		width: 100%;
		background: #000000;
		overflow: hidden;
	}
	
	.trendsCon1{
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		min-height: calc(100vh - 70px);
		padding-bottom: 150px;
		text-align: center;
		z-index: 3;
	}
	
	.trendsTitle {
		background: linear-gradient(180deg, #d2eaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		width: 60%;
		padding-top: 80px;
		margin: auto;
		font-weight: bold;
		font-family: DingTalkJinBuTi;
		text-align: center;
		letter-spacing: 2px;
		padding-bottom: 35px;
	}
	
	
	.trendsVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.trendsBg{
		width: 100%;
		opacity: 0.2;
	}
	
	.trendsVideo::before,
	.trendsVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.trendsVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.trendsVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.trend1Table{
		width: 100%;
		padding: 30px;
		display: inline-block;
	}
	
	.trend1Item{
		display: inline-flex;
		align-items: center;
		width: 100%;
		margin-bottom: 30px;
		position: relative;
	}
	
	.trend1Left{
		width: 60%;
		letter-spacing: 2px;
		text-align: left;
		padding-bottom: 30px;
		padding: 30px 0 30px 0;
		border-bottom: 1px solid rgba(255, 255, 255, 0.3);
	}
	
	.trend1Right{
		width: 30%;
		text-align: right;
		font-size: 15px;
		font-weight: 100;
		height: 100%;
		transform:translate(-35%,46%);
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		position: absolute;
		right: 0;
	}
	
	.trend1Right::before{
		content: '';
		position: absolute;
		width: 15px;
		border-radius: 50%;
		height: 15px;
		background: rgba(192, 226, 255, 1.0);
		box-shadow: 0px 1px 5px 3px rgba(255, 255, 255, 0.7);
		left: 10px;
		top: -3px;
	}
	
	.trend1Right::after{
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		left: 17.5px;
		top: 20px;
		border-left: 1px dashed #c9e2ff;
	}
	
	.newsTitle{
		font-weight: bold;
		color: #e8e8e8;
		font-size: 16px;
		font-family: T3;
	}
	
	
	.trend1RightEnd{
		width: 30%;
		text-align: right;
		font-size: 15px;
		font-weight: 100;
		height: 100%;
		transform:translate(-35%,46%);
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		position: absolute;
		right: 0;
		top: -3px;
	}
	
	.trend1RightEnd::before{
		content: '';
		position: absolute;
		width: 15px;
		border-radius: 50%;
		height: 15px;
		background: rgba(192, 226, 255, 1.0);
		box-shadow: 0px 1px 5px 3px rgba(255, 255, 255, 0.7);
		left: 10px;
	}
	
	.newsCon{
		font-size: 16px;
		margin-top: 20px;
		line-height: 25px;
		color: #ffffff;
		font-family: T1;
	}
}