@media (min-width: 769px) {
	.header-transparent {
		position: sticky;
		top: 0;
		z-index: 9999;
		width: 100%;
		background-color: rgba(7, 0, 25, 1.0);
		
	}
	
	.header-solid {
		position: sticky;
		top: 0;
		z-index: 9999;
		width: 100%;
		background-color: rgba(0, 0, 0, 0.9);
		animation: fadeIn 0.8s ease;
	}
	
	.header-phone{
		display: none;
	}
	
	@keyframes fadeIn {
		from {
			opacity: 0.5;
		}
		to {
			opacity: 1;
		}
	}
	
	@keyframes fadeOut {
		from {
			opacity: 1;
		}
		to {
			opacity: 0.5;
		}
	}
	
	.header-wrapper {
		display: flex;
		max-width: 1240px;
		height: 100%;
		padding-right: 20px;
		padding-left: 20px;
		margin: 0 auto;
		height: 70px;
		position: relative;
	}
	
	.header-logo {
		height: 46px;
		margin-top: 12px;
		position: absolute;
	}
	
	.header-menu {
		/* padding-left: 70px; */
		margin: auto;
	}
}
@media screen and (max-width: 769px) {
	.header-transparent {
		display: none;
	}
	.header-solid {
		display: none;
	}
	
	.header-phone{
		position: sticky;
		top: 0;
		left: 0;
		z-index: 9999;
		width: 100%;
		background-color: rgba(7, 0, 25, 1.0);
	}
	
	.header-wrapper {
		display: flex;
		width: 100%;
	}
	
	.header-logos{
		width: 50%;
		height: 70px;
	}
	
	.header-logo {
		height: 36px;
		margin-top: 17px;
		padding-left: 10px;
	}
	
	.header-menu {
		color: #fff;
		width: 50%;
		height: 70px;
		line-height: 70px;
		text-align: right;
		padding-right: 20px;
		font-size: 20px;
	}
	
	.header-menus{
		margin-top: 70px;
	}
	
	.header-close{
		display: block;
		text-align: right;
		font-size: 20px;
		margin-bottom: 10px;
		color: #fff;
	}
}
