@media (min-width: 769px) {
	.tocView {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		background: #000;
	}

	.tociframe {
		position: absolute;
		width: 100%;
		height: 100%;
		border: none;
		/* 如果不想显示边框 */
	}
}

@media screen and (max-width: 769px) {
	.tocView {
		position: relative;
		width: 100%;
		padding-bottom: 100%;
		background: #000;
	}

	.tociframe {
		position: absolute;
		width: 100%;
		height: 100%;
		border: none;
		/* 如果不想显示边框 */
	}
}