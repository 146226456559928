@media (min-width: 769px) {
	.iastView {
		width: 100%;
		background: #000;
	
	}
	
	.iastVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.iastVideo::before,
	.iastVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.iastVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 90%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.iastContent {
		margin: auto;
		width: 100%;
		padding-top: 30vh;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.iastTitle {
		background: linear-gradient(180deg, #ffffff 0%, #55aaff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 35px;
		width: 620px;
		margin: auto;
		text-align: center;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
		letter-spacing: 1px;
		padding-bottom: 35px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.iastTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-size: 20px;
		font-family: DingTalkJinBuTi;
		margin-top: 35px;
		letter-spacing: 2px;
	}
	.iastTitle3 {
		width: 600px;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}
	
	.iastBtn {
		margin-top: 35px;
		border: 0.1px solid rgba(85, 170, 255, 0.6);
		box-shadow: 0px 1px 15px 1px rgba(85, 170, 255, 0.7);
		padding-top: 12px;
		font-size: 16px;
		font-weight: 100;
		padding-bottom: 12px;
		border-radius: 3px;
		font-family: T3;
	}
	.iastTitleBtn {
		width: 200px;
		margin: 50px auto;
		background: rgba(85, 170, 255, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(85, 170, 255, 0.8);
		/* background: rgba(138, 138, 138, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(138, 138, 138, 0.8); */
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 3px;
		cursor: pointer;
		color: #ffffff;
		font-family: T3;
	}
	
	
	.iastTitleBtn:hover {
		background: rgba(85, 170, 255, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(85, 170, 255, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.iastCon2 {
		width: 1280px;
		margin: auto;
		padding-top: 30vh;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.iastCon2Phone {
		display: none;
	}
	
	.iast2Card1 {
		margin-top: 80px;
	}
	
	.iast2cImgBg {
		width: 100%;
		border-radius: 20px;
	}
	
	.iast2cImgBg::before,
	.iast2cImgBg::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.iast2cImgBg::before {
		top: 0;
		left: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iast2cImgBg::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iast2cImg {
		width: 100%;
		opacity: 0.5;
	}
	
	.iast2cView {
		width: 370px;
		display: inline-block;
	}
	
	.iast2cText1 {
		background: linear-gradient(90deg, #b4dfff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		letter-spacing: 2px;
		margin-top: 120px;
		padding-bottom: 30px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.iast2cText2 {
		margin-top: 30px;
		text-align: left;
		font-size: 16px;
		line-height: 30px;
		letter-spacing: 2px;
		color: #ffffff;
		font-family: T1;
	}
	
	.iastCon3 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 100px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.iastCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 155px;
	}
	
	.iastCardCon::before,
	.iastCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 50px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.iastCardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastCardImg{
		width: 100%;
		position: relative;
		top: 0;
		opacity: 0.7;
	}
	
	.iastc3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.iast3Div{
	}
	
	.iast3Div1{
		width: 520px;
		height: 300px;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		top: 30px;
		left: -15px;
		margin-right: 25px;
		margin-top: 30px;
		background: rgba(0, 0, 0, 0.2);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
		transform: rotate(-8deg);
	}
	
	.iast3Div2{
		width: 520px;
		height: 300px;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		margin-top: 30px;
		top: 30px;
		left: 15px;
		overflow: hidden;
		margin-left: 25px;
		background: rgba(0, 0, 0, 0.2);
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
		transform: rotate(8deg);
	}
	
	
	.iast3Div3{
		width: 520px;
		height: 300px;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		margin-top: 50px;
		top: 10px;
		overflow: hidden;
		background: rgba(0, 0, 0, 0.2);
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
	}
	
	.iast3cTitle{
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.iast3cImg{
		width: 300px;
		height: 70px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(67, 67, 67, 0.1) 0%, #000000 10%);
	}
	
	.iastImgS{
		width: 60px;
		margin-top: 5px;
		height: 60px;
		opacity: 0.6;
	}
	
	.iast3cText1{
		margin-top: 10px;
		padding: 15px 0 10px 20px;
		letter-spacing: 1px;
		line-height: 20px;
		font-size: 15px;
		text-align: left;
		font-family: T1;
		color: #fff;
	}
	
	.iast3cText2{
		padding: 15px 0 15px 20px;
		letter-spacing: 1px;
		line-height: 20px;
		font-size: 15px;
		text-align: left;
		font-family: T1;
		color: #fff;
	}
	
	.iastIconr{
		color: #00aa00;
		font-size: 15px;
		margin-right: 10px;
	}
}
@media screen and (max-width: 769px) {
	.iastView {
		width: 100%;
		background: #000;
	
	}
	
	.iastVideo {
		display: none;
	}

	.iastVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.iastContent {
		margin: auto;
		width: 100%;
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.iastTitle {
		background: linear-gradient(180deg, #ffffff 0%, #55aaff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		width: 100%;
		margin: auto;
		text-align: center;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
		letter-spacing: 1px;
		padding-bottom: 20px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.iastTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-size: 18px;
		font-family: DingTalkJinBuTi;
		margin-top: 20px;
		letter-spacing: 2px;
	}
	.iastTitle3 {
		width: 95%;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}
	
	.iastBtn {
		margin-top: 35px;
		border: 0.1px solid rgba(85, 170, 255, 0.6);
		box-shadow: 0px 1px 5px 1px rgba(85, 170, 255, 0.7);
		padding-top: 8px;
		font-size: 11px;
		font-weight: 100;
		padding-bottom: 8px;
		border-radius: 3px;
		font-family: T3;
	}
	.iastTitleBtn {
		width: 200px;
		margin: 35px auto;
		background: rgba(85, 170, 255, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(85, 170, 255, 0.8);
		/* background: rgba(138, 138, 138, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(138, 138, 138, 0.8); */
		padding-top: 8px;
		padding-bottom: 8px;
		border-radius: 3px;
		cursor: pointer;
		color: #ffffff;
		font-size: 14px;
		font-family: T3;
	}
	
	
	.iastTitleBtn:hover {
		background: rgba(85, 170, 255, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(85, 170, 255, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.iastCon2 {
		display: none;
	}
	
	.iastCon2Phone {
		width: 100%;
	}
	
	.iast2Card1 {
		margin-top: 50px;
	}
	
	.iast2cImgBg {
		width: 100%;
		border-radius: 20px;
	}
	
	.iast2cImgBg::before,
	.iast2cImgBg::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.iast2cImgBg::before {
		top: 0;
		left: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iast2cImgBg::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iast2cImg {
		width: 100%;
	}
	
	.iast2cView {
		width: 80%;
		display: inline-block;
	}
	
	.iast2cText1 {
		background: linear-gradient(90deg, #b4dfff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 16px;
		letter-spacing: 2px;
		margin-top: 30px;
		padding-bottom: 20px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.iast2cText2 {
		margin-top: 20px;
		text-align: left;
		font-size: 14px;
		line-height: 30px;
		letter-spacing: 2px;
		color: #ffffff;
		font-family: T1;
	}
	
	.iastCon3 {
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 100px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.iastCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 155px;
	}
	
	.iastCardCon::before,
	.iastCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 50px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.iastCardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.iastCardImg{
		width: 100%;
		position: relative;
		top: 0;
		opacity: 0.7;
	}
	
	.iastc3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.iast3Div{
	}
	
	.iast3Div1{
		width: 80%;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		background: rgba(0, 0, 0, 0.2);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
	}
	
	.iast3Div2{
		width: 80%;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		background: rgba(0, 0, 0, 0.2);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
		margin-top: 50px;
	}
	
	
	.iast3Div3{
		width: 80%;
		border-radius: 3px;
		display: inline-block;
		position: relative;
		background: rgba(0, 0, 0, 0.2);
		overflow: hidden;
		box-shadow: 0px 1px 30px 5px rgba(85, 170, 255, 0.8);
		margin-top: 50px;
	}
	
	.iast3cTitle{
		background: linear-gradient(90deg, #55aaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-weight: bold;
		padding: 30px;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
		font-family: DingTalkJinBuTi;
	}
	
	.iast3cImg{
		width: 300px;
		height: 70px;
		position: absolute;
		bottom: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(67, 67, 67, 0.1) 0%, #000000 10%);
	}
	
	.iastImgS{
		width: 60px;
		margin-top: 5px;
		height: 60px;
		opacity: 0.6;
	}
	
	.iast3cText1{
		margin-top: 10px;
		padding: 15px 0 10px 20px;
		letter-spacing: 1px;
		line-height: 20px;
		font-size: 15px;
		text-align: left;
		font-family: T1;
		color: #fff;
	}
	
	.iast3cText2{
		padding: 15px 0 15px 20px;
		letter-spacing: 1px;
		line-height: 20px;
		font-size: 15px;
		text-align: left;
		font-family: T1;
		color: #fff;
	}
	
	.iastIconr{
		color: #00aa00;
		font-size: 15px;
		margin-right: 10px;
	}
}
