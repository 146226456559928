@media (min-width: 769px) {
	.homeView {
		width: 100%;
		background: #000;
	}
	
	.homeVideo {
		width: 100%;
		position: absolute;
		z-index: -1;
		background: #000;
		top: 0;
		opacity: 0.35;
	}
	
	.homeVideoPhone{
		display: none;
	}
	
	.homeVideoS {
		width: 100%;
	}
	
	.homeContent {
		margin: auto;
		width: 100%;
		padding-top: 30vh;
		padding-bottom: 100px;
		background: rgba(0, 0, 0, 0.5);
		color: #000;
		position: relative;
		z-index: 2;
	}
	
	.homeTitle {
		background: linear-gradient(180deg, #ffffff 0%, #9ce0ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 37px;
		width: 700px;
		margin: auto;
		text-align: center;
		font-family: DingTalkJinBuTi;
		font-weight: 100;
		letter-spacing: 1px;
		padding-bottom: 35px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.homeTitle2 {
		width: 500px;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}
	
	.ht2Btn {
		margin-top: 35px;
		border: 0.1px solid rgba(85, 170, 255, 0.6);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.7);
		padding-top: 12px;
		font-size: 18px;
		letter-spacing: 1px;
		font-weight: 100;
		padding-bottom: 12px;
		background: rgba(0, 0, 0, 0.5);
		border-radius: 3px;
		font-family: T3;
	}
	
	.homeTitle3 {
		width: 410px;
		margin: 50px auto;
		text-align: center;
		color: #ffffff;
	}
	
	.ht3Btn {
		background: rgba(31, 124, 255, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.7);
		padding-top: 13px;
		padding-bottom: 13px;
		border-radius: 3px;
		cursor: pointer;
		font-family: T3;
		font-size: 16px;
	}
	
	.ht3Btn:hover {
		box-shadow: 0px 1px 30px 1px rgba(164, 205, 255, 0.7);
		background: rgba(31, 124, 255, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.homeTitle4 {
		width: 360px;
		margin: 50px auto;
		text-align: center;
		color: #ffffff;
	}
	
	.homeT4dis{
		width: 100%;
		display: inline-flex;
	}
	
	.homeT4item{
		width: 20%;
	}
	
	.ht4Btn {
		font-size: 23px;
		cursor: pointer;
	}
	
	.homeView1 {
		text-align: center;
		margin-top: 30vh;
		margin-bottom: 100px;
		position: relative;
	}
	
	.homeView1Phone{
		display: none;
	}
	
	.hv1dev{
		position: absolute;
		width: 100%;
		height: auto;
		top: 0;
		z-index: -2;
		opacity: 0.5;
	}
	
	.homeRight{
		display: block;
		text-align: right;
		overflow: hidden;
		position: relative;
		top: 90px;
		width: 100%;
		height: 648px;
		opacity: 0.8;
	}
	
	.homeRight::before,
	.homeRight::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 648px;
		top: 0;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.homeRight::before {
		bottom: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 85%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeRight::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 85%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeLeft{
		display: block;
		text-align: right;
		overflow: hidden;
		position: relative;
		top: 120px;
		width: 100%;
	}
	
	.homeLeft::before,
	.homeLeft::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 0;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.homeLeft::before {
		bottom: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 80%,  rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeLeft::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv1Bg {
		width: 1280px;
		display: inline-block;
		padding-bottom: 50px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		box-shadow: 0px 1px 30px 10px rgba(164, 205, 255, 0.7);
		border-radius: 3px;
		text-align: center;
		position: relative;
		overflow: hidden;
	}
	
	.hv1Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #e8e8e8;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv1Card1 {
		width: 300px;
		border-radius: 15px;
		background: rgba(35, 39, 60, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
	}
	
	.hv1Card2 {
		width: 300px;
		border-radius: 15px;
		background: rgba(35, 39, 60, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
		margin-top: 50px;
	}
	
	.hv1CardTitle {
		font-size: 18px;
		letter-spacing: 5px;
		padding: 15px;
		color: #e8e8e8;
		background: linear-gradient(90deg, #ffffff 0%, #eff1ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
	}
	
	.hv1CardText1 {
		width: 200px;
		margin-left: 55px;
		text-align: left;
		letter-spacing: 3px;
		display: flex;
		align-items: center;
		color: #e8e8e8;
		font-size: 16px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardText2 {
		width: 200px;
		margin-left: 55px;
		text-align: left;
		color: #e8e8e8;
		font-size: 16px;
		display: flex;
		align-items: center;
		letter-spacing: 3px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardText3 {
		width: 200px;
		margin-left: 55px;
		text-align: left;
		color: #e8e8e8;
		display: flex;
		align-items: center;
		font-size: 16px;
		letter-spacing: 3px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardBtn {
		margin-top: 10px;
		font-weight: 100;
		font-size:16px;
		letter-spacing: 2px;
		padding: 15px;
		border-top: 1px solid rgba(222, 244, 255, 0.2);
		cursor: pointer;
		color: #ffaa00;
		font-family: T3;
	}
	
	.hv1Icon {
		color: #a9debf;
		font-size: 20px;
		margin-right: 8px;
	}
	
	.hv1BtnIcon {
		color: #ffaa00;
		font-size: 15px;
		margin-left: 2px;
	}
	
	.hv1Round {
		display: inline-block;
		margin-top: 15px;
		width: 420px;
		height: 420px;
		border-radius: 50%;
		box-shadow: 0px 1px 30px 1px rgba(255, 255, 255, 0.6);
		background: radial-gradient(circle, rgba(85, 170, 255, 0.35) 0%, rgba(248, 119, 221, 0.28) 100%);
	}
	
	.hv1rView {
		width: 310px;
		height: 310px;
		margin: 55px;
		border-radius: 50%;
		position: relative;
		/* border: 1px solid rgba(255, 255, 255, 0.3); */
	}
	
	.hv1rSaas {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		left: -5px;
		top: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		font-family: DingTalkJinBuTi;
		background: rgba(250, 118, 43, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 1.0);
	}
	
	.hv1rSaas:hover {
		box-shadow: 0px 1px 30px 1px rgba(250, 118, 43, 1.0);
	}
	
	.hv1Arrow1 {
		position: absolute;
		left: -110px;
		top: 10px;
	}
	
	.hv1Arrow2 {
		position: absolute;
		left: -110px;
		bottom: 10px;
	}
	
	.hv1Arrow3 {
		position: absolute;
		right: -110px;
		top: 10px;
	}
	
	.hv1Arrow4 {
		position: absolute;
		right: -110px;
		bottom: 10px;
	}
	
	.hv1rSast {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		left: -5px;
		bottom: -5px;
		font-size: 14px;
		font-weight: 200;
		color: #e8e8e8;
		background: rgba(20, 154, 67, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(20, 154, 67, 1.0);
		font-family: DingTalkJinBuTi;
	}
	
	.hv1rSast:hover {
		box-shadow: 0px 1px 30px 1px rgba(20, 154, 67, 1.0);
	}
	
	.hv1rSca {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		right: -5px;
		top: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		font-family: DingTalkJinBuTi;
		background: rgba(170, 85, 255, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(170, 85, 255, 1.0);
	}
	
	.hv1rSca:hover {
		box-shadow: 0px 1px 30px 1px rgba(170, 85, 255, 1.0);
	}
	
	.hv1rIast {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		right: -5px;
		bottom: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		background: rgba(47, 148, 255, 1.0);
		font-family: DingTalkJinBuTi;
		box-shadow: 0px 1px 15px 1px rgba(47, 148, 255, 1.0);
	}
	
	.hv1rIast:hover {
		box-shadow: 0px 1px 30px 1px rgba(47, 148, 255, 1.0);
	}
	
	.hv1rTitleR {
		width: 200px;
		height: 200px;
		top: 55px;
		margin: auto;
		position: relative;
		border-radius: 50%;
		/* background: rgba(35, 39, 60, 0.7); */
		background: url('../../img/homexyd.gif');
		font-family: T3;
		box-shadow: 0px 1px 15px 1px rgba(170, 170, 255, 0.7);
	}
	
	.hv1rTitle1 {
		font-size: 22px;
		font-weight: 200;
		color: #e8e8e8;
		border-radius: 50%;
		margin: auto;
		position: relative;
		top: 50px;
		width: 100px;
		height: 100px;
		line-height: 100px;
		background: radial-gradient(circle, rgba(33, 66, 255, 0.9) 0%, rgba(35, 39, 60, 0.9) 100%);
		letter-spacing: 2px;
	}
	
	.homeView2 {
		width: 1280px;
		margin: auto;
		padding-bottom: 100px;
		border-radius: 3px;
		text-align: center;
	}
	
	.homeView2Phone{
		display: none;
	}
	
	.hv2Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv2Card {
		width: 390px;
		height: 420px;
		background: linear-gradient(90deg, rgba(14, 18, 29, 1.0) 0%, rgba(56, 66, 90, 1.0) 100%);
		margin-top: 25px;
		border-radius: 15px;
		overflow: hidden;
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
		transition: all 0.3s ease;
	}
	
	.hv2Card:hover {
		box-shadow: 0px 1px 30px 1px rgba(164, 205, 255, 0.9);
		transition: all 0.3s ease;
	}
	
	.hv2Card:hover .hv2CardImg {
		transition: all 1s ease;
		transform: scale(1.2);
	}
	
	.hv2CardDiv {
		width: 390px;
		height: 200px;
		position: relative;
		overflow: hidden;
	}
	
	.hv2CardImg {
		width: 390px;
		height: 220px;
		position: relative;
		top: -15px;
	}
	
	.hv2cTitle {
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 18px;
		padding: 25px 20px 0 30px;
		font-family: T3;
	}
	
	.hv2cText1 {
		font-size: 14px;
		padding: 8px 8px 15px 8px;
		border-bottom: 0.3px solid rgba(170, 170, 255, 0.2);
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: T1;
	}
	
	.hv2cText2 {
		width: 240px;
		text-align: left;
		background: linear-gradient(90deg, #f6fdff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin-left: 60px;
		padding: 20px 20px 0 20px;
		font-size: 16px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText2wy {
		width: 240px;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin-left: 60px;
		padding: 35px 20px 0 20px;
		font-size: 16px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText3 {
		width: 240px;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin-left: 60px;
		font-size: 16px;
		padding: 20px 20px 0 20px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText4 {
		width: 240px;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		margin-left: 60px;
		padding: 20px 20px 0 20px;
		font-size: 16px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2Icon {
		color: #f4f4f4;
		font-size: 15px;
		margin-right: 5px;
	}
	
	.homeView3 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		text-align: center;
	}
	
	.hv3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv3Div {
		position: relative;
		overflow: hidden;
	}
	
	.hv3Card {
		font-size: 16px;
		width: 450px;
		position: relative;
		height: 180px;
		display: inline-block;
		background: radial-gradient(circle, rgba(65, 65, 65, 0.3) 0%, rgba(35, 39, 60, 0.8) 100%);
		border-radius: 8px;
		letter-spacing: 2px;
		margin: 30px 0 30px 0px;
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.7);
		color: #e8e8e8;
		transition: all 0.3s ease;
		z-index: 2;
	}
	
	.hv3Card:hover {
		box-shadow: 0px 1px 30px 1px rgba(164, 205, 255, 0.7);
		transition: all 0.3s ease;
	}
	
	.hv3CardTitle{
		padding: 30px 0 10px 0;
		font-family: T3;
	}
	
	.hv3CardEng {
		font-size: 14px;
		color: #f3f3f3;
		padding: 0 30px 0 30px;
		line-height: 25px;
		text-align: left;
		font-family: T1;
	}
	
	.hv3CardCon {
		width: 100%;
		position: absolute;
	}
	
	.hv3CardCon::before,
	.hv3CardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.hv3CardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv3CardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv3CardImg {
		width: 100%;
		z-index: 2;
	}
}
@media screen and (max-width: 769px) {
	.homeView {
		width: 100%;
		background: #000;
		overflow: hidden;
	}
	
	.homeVideo {
		display: none;
	}
	
	.homeVideoPhone{
		position: absolute;
		opacity: 0.3;
		top: 0;
		width: 100%;
	}
	
	.homeVideoS {
		width: 100%;
	}
	
	.homeContent {
		margin: auto;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
		color: #000;
		position: relative;
		z-index: 2;
	}
	
	.homeTitle {
		background: linear-gradient(180deg, #ffffff 0%, #9ce0ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		margin: auto;
		text-align: center;
		font-family: DingTalkJinBuTi;
		font-weight: 100;
		padding-top: 80px;
		padding-bottom: 35px;
	}
	
	.homeTitle2 {
		width: 80%;
		margin: auto;
		text-align: center;
		color: #ffffff;
	}
	
	.ht2Btn {
		border: 0.1px solid rgba(85, 170, 255, 0.6);
		box-shadow: 0px 1px 5px 1px rgba(164, 205, 255, 0.7);
		padding-top: 6px;
		font-size: 13px;
		letter-spacing: 1px;
		font-weight: 100;
		padding-bottom: 6px;
		border-radius: 3px;
		font-family: T3;
	}
	
	.homeTitle3 {
		display: none;
	}
	
	.ht3Btn {
		background: rgba(31, 124, 255, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.7);
		padding-top: 13px;
		padding-bottom: 13px;
		border-radius: 3px;
		cursor: pointer;
		font-family: T3;
		font-size: 16px;
	}
	
	.ht3Btn:hover {
		box-shadow: 0px 1px 30px 1px rgba(164, 205, 255, 0.7);
		background: rgba(31, 124, 255, 1.0);
		transition: all 0.3s ease-in-out;
	}
	
	.homeTitle4 {
		width: 360px;
		margin: 50px auto;
		text-align: center;
		color: #ffffff;
	}
	
	.homeT4dis{
		width: 100%;
		display: inline-flex;
	}
	
	.homeT4item{
		width: 20%;
	}
	
	.ht4Btn {
		font-size: 23px;
		cursor: pointer;
	}
	
	.homeView1 {
		display: none;
	}
	
	.homeView1Phone {
		text-align: center;
		margin-top: 100px;
		margin-bottom: 100px;
		position: relative;
	}
	.hv1dev{
		display: none;
	}
	
	.homeRight{
		display: block;
		text-align: right;
		overflow: hidden;
		position: relative;
		top: 90px;
		width: 100%;
		height: 648px;
		opacity: 0.8;
	}
	
	.homeRight::before,
	.homeRight::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 648px;
		top: 0;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.homeRight::before {
		bottom: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 85%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeRight::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 85%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeLeft{
		display: block;
		text-align: right;
		overflow: hidden;
		position: relative;
		top: 120px;
		width: 100%;
	}
	
	.homeLeft::before,
	.homeLeft::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 0;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.homeLeft::before {
		bottom: 0;
		right: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.0) 80%,  rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.homeLeft::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv1Bg {
		width: 1280px;
		display: inline-block;
		padding-bottom: 50px;
		border: 1px solid rgba(255, 255, 255, 0.5);
		box-shadow: 0px 1px 30px 10px rgba(164, 205, 255, 0.7);
		border-radius: 3px;
		text-align: center;
		position: relative;
		overflow: hidden;
	}
	
	.hv1Title {
		text-align: center;
		padding: 30px 30px 0 30px;
		font-size: 20px;
		color: #e8e8e8;
		letter-spacing: 0.1px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv1Card1 {
		width: 80%;
		border-radius: 15px;
		background: rgba(35, 39, 60, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
		margin-top: 50px;
	}
	
	.hv1Card2 {
		width: 80%;
		border-radius: 15px;
		background: rgba(35, 39, 60, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
		margin-top: 50px;
	}
	
	.hv1CardTitle {
		font-size: 15px;
		letter-spacing: 5px;
		padding: 15px;
		color: #e8e8e8;
		background: linear-gradient(90deg, #ffffff 0%, #eff1ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
	}
	
	.hv1CardText1 {
		width: 100%;
		transform: translate(23%);
		text-align: left;
		letter-spacing: 3px;
		display: flex;
		align-items: center;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardText2 {
		width: 100%;
		transform: translate(23%);
		text-align: left;
		color: #e8e8e8;
		font-size: 14px;
		display: flex;
		align-items: center;
		letter-spacing: 3px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardText3 {
		width: 100%;
		transform: translate(23%);
		text-align: left;
		color: #e8e8e8;
		display: flex;
		align-items: center;
		font-size: 14px;
		letter-spacing: 3px;
		font-weight: 200;
		padding: 8px;
		font-family: T3;
	}
	
	.hv1CardBtn {
		margin-top: 10px;
		font-weight: 100;
		font-size: 14px;
		letter-spacing: 2px;
		padding: 15px;
		border-top: 1px solid rgba(222, 244, 255, 0.2);
		cursor: pointer;
		color: #ffaa00;
		font-family: T3;
	}
	
	.hv1Icon {
		color: #a9debf;
		font-size: 20px;
		margin-right: 8px;
	}
	
	.hv1BtnIcon {
		color: #ffaa00;
		font-size: 15px;
		margin-left: 2px;
	}
	
	.hv1Round {
		display: inline-block;
		margin-top: 15px;
		width: 420px;
		height: 420px;
		border-radius: 50%;
		box-shadow: 0px 1px 30px 1px rgba(255, 255, 255, 0.6);
		background: radial-gradient(circle, rgba(85, 170, 255, 0.35) 0%, rgba(248, 119, 221, 0.28) 100%);
	}
	
	.hv1rView {
		width: 310px;
		height: 310px;
		margin: 55px;
		border-radius: 50%;
		position: relative;
		/* border: 1px solid rgba(255, 255, 255, 0.3); */
	}
	
	.hv1rSaas {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		left: -5px;
		top: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		font-family: DingTalkJinBuTi;
		background: rgba(250, 118, 43, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 1.0);
	}
	
	.hv1rSaas:hover {
		box-shadow: 0px 1px 30px 1px rgba(250, 118, 43, 1.0);
	}
	
	.hv1Arrow1 {
		position: absolute;
		left: -110px;
		top: 10px;
	}
	
	.hv1Arrow2 {
		position: absolute;
		left: -110px;
		bottom: 10px;
	}
	
	.hv1Arrow3 {
		position: absolute;
		right: -110px;
		top: 10px;
	}
	
	.hv1Arrow4 {
		position: absolute;
		right: -110px;
		bottom: 10px;
	}
	
	.hv1rSast {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		left: -5px;
		bottom: -5px;
		font-size: 14px;
		font-weight: 200;
		color: #e8e8e8;
		background: rgba(20, 154, 67, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(20, 154, 67, 1.0);
		font-family: DingTalkJinBuTi;
	}
	
	.hv1rSast:hover {
		box-shadow: 0px 1px 30px 1px rgba(20, 154, 67, 1.0);
	}
	
	.hv1rSca {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		right: -5px;
		top: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		font-family: DingTalkJinBuTi;
		background: rgba(170, 85, 255, 1.0);
		box-shadow: 0px 1px 15px 1px rgba(170, 85, 255, 1.0);
	}
	
	.hv1rSca:hover {
		box-shadow: 0px 1px 30px 1px rgba(170, 85, 255, 1.0);
	}
	
	.hv1rIast {
		position: absolute;
		width: 100px;
		height: 100px;
		border-radius: 50px;
		line-height: 100px;
		right: -5px;
		bottom: -5px;
		color: #e8e8e8;
		font-size: 14px;
		font-weight: 200;
		background: rgba(47, 148, 255, 1.0);
		font-family: DingTalkJinBuTi;
		box-shadow: 0px 1px 15px 1px rgba(47, 148, 255, 1.0);
	}
	
	.hv1rIast:hover {
		box-shadow: 0px 1px 30px 1px rgba(47, 148, 255, 1.0);
	}
	
	.hv1rTitleR {
		width: 200px;
		height: 200px;
		top: 55px;
		margin: auto;
		position: relative;
		border-radius: 50%;
		/* background: rgba(35, 39, 60, 0.7); */
		background: url('../../img/homexyd.gif');
		font-family: T3;
		box-shadow: 0px 1px 15px 1px rgba(170, 170, 255, 0.7);
	}
	
	.hv1rTitle1 {
		font-size: 22px;
		font-weight: 200;
		color: #e8e8e8;
		border-radius: 50%;
		margin: auto;
		position: relative;
		top: 50px;
		width: 100px;
		height: 100px;
		line-height: 100px;
		background: radial-gradient(circle, rgba(33, 66, 255, 0.9) 0%, rgba(35, 39, 60, 0.9) 100%);
		letter-spacing: 2px;
	}
	
	.homeView2 {
		display: none;
	}
	
	.homeView2Phone{
		text-align: center;
	}
	
	.hv2Title {
		text-align: center;
		padding: 20px;
		font-size: 18px;
		color: #ffffff;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv2Card {
		width: 80%;
		height: auto;
		background: linear-gradient(90deg, rgba(14, 18, 29, 1.0) 0%, rgba(56, 66, 90, 1.0) 100%);
		margin-top: 25px;
		padding-bottom: 25px;
		margin-bottom: 25px;
		border-radius: 15px;
		overflow: hidden;
		box-shadow: 0px 1px 15px 1px rgba(164, 205, 255, 0.9);
		display: inline-block;
		transition: all 0.3s ease;
	}
	
	.hv2Card:hover {
		box-shadow: 0px 1px 30px 1px rgba(164, 205, 255, 0.9);
		transition: all 0.3s ease;
	}
	
	.hv2Card:hover .hv2CardImg {
		transition: all 1s ease;
		transform: scale(1.2);
	}
	
	.hv2CardDiv {
		width: 390px;
		height: 200px;
		position: relative;
		overflow: hidden;
	}
	
	.hv2CardImg {
		width: 390px;
		height: 220px;
		position: relative;
		top: -15px;
	}
	
	.hv2cTitle {
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 15px;
		padding: 15px 10px 0 10px;
		font-family: T3;
	}
	
	.hv2cText1 {
		font-size: 12px;
		padding: 8px 8px 15px 8px;
		border-bottom: 0.3px solid rgba(170, 170, 255, 0.2);
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: T1;
	}
	
	.hv2cText2 {
		width: 100%;
		text-align: left;
		background: linear-gradient(90deg, #f6fdff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transform: translate(15%);
		padding: 20px 20px 0 20px;
		font-size: 13px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText2wy {
		width: 100%;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transform: translate(15%);
		padding: 35px 20px 0 20px;
		font-size: 13px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText3 {
		width: 100%;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transform: translate(15%);
		font-size: 13px;
		padding: 20px 20px 0 20px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2cText4 {
		width: 100%;
		text-align: left;
		background: linear-gradient(90deg, #ddf5ff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		transform: translate(15%);
		padding: 20px 20px 0 20px;
		font-size: 13px;
		letter-spacing: 1px;
		font-family: T3;
	}
	
	.hv2Icon {
		color: #f4f4f4;
		font-size: 13px;
		margin-right: 5px;
	}
	
	.homeView3 {
		width: 90%;
		margin: auto;
		border-radius: 3px;
		text-align: center;
		padding-bottom: 50px;
	}
	
	.hv3Title {
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.hv3Div {
		position: relative;
		overflow: hidden;
	}
	
	.hv3Card {
		width: 90%;
		height: auto;
		padding-bottom: 20px;
		position: relative;
		display: inline-block;
		background: radial-gradient(circle, rgba(65, 65, 65, 0.3) 0%, rgba(35, 39, 60, 0.8) 100%);
		border-radius: 8px;
		letter-spacing: 2px;
		margin: 15px 0 15px 0;
		box-shadow: 0px 1px 5px 1px rgba(164, 205, 255, 0.7);
		color: #e8e8e8;
		transition: all 0.3s ease;
		z-index: 2;
	}
	
	.hv3Card:hover {
		box-shadow: 0px 1px 10px 1px rgba(164, 205, 255, 0.7);
		transition: all 0.3s ease;
	}
	
	.hv3CardTitle{
		padding: 15px 8px 0 8px;
		font-family: T3;
		font-size: 13px;
		line-height: 20px;
		text-align: left;
	}
	
	.hv3CardEng {
		font-size: 10px;
		color: #f3f3f3;
		padding: 0 8px 0 8px;
		line-height: 20px;
		text-align: left;
		font-family: T1;
	}
	
	.hv3CardCon {
		width: 100%;
		position: absolute;
	}
	
	.hv3CardCon::before,
	.hv3CardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.hv3CardCon::before {
		top: 0;
		left: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv3CardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.hv3CardImg {
		width: 100%;
		z-index: 2;
	}
}
