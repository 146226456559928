.page_contain{
    padding-right: 20px;
    padding-left: 20px;
    max-width: 100%;
    background-color: aliceblue;
}
.m-title{
    font-size: 30px;
    font-weight: 600;
}
.page_dic{
    margin-left: 10px;
}
.author{
    font-size: 20px;
}
.like{
    padding: 2px 10px;
    margin: 0 10px;
    color: #ec5959;
    border: 1px solid #ec5959;
    background-color: #fff;
    border-radius: 50px;
}
.dic{
    display: inline-block;
    margin-top: 10px;
    margin-right: 20px;
    font-size: 17px;
    color: #ec5959;
}
.m-dic{
    display: inline-block;
    margin-right: 20px;
    font-size: 17px;
    color: rgba(120, 122, 122, 0.773);
}
.qianyan{
    margin: 30px 10px;
    font-size: 24px;
    font-weight: 600;
}
.con{
    margin-left: 10px;
    color: #484747;
    font-size: 20px;
    line-height: 1.5;
}