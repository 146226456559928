@media (min-width: 769px) {
	.scaView {
		width: 100%;
		background: #000;
	}
	
	.scaVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.scaVideo::before,
	.scaVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.scaVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.scaContent {
		margin: auto;
		width: 100%;
		padding-top: 30vh;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.scaTitle {
		background: linear-gradient(180deg, #ffffff 0%, #ffe0aa 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 35px;
		width: 620px;
		margin: auto;
		text-align: center;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
		letter-spacing: 1px;
		padding-bottom: 35px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.scaTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #ffe0aa 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		font-size: 20px;
		margin-top: 35px;
		letter-spacing: 2px;
	}
	
	.scaTitleBtn {
		width: 200px;
		margin: 50px auto;
		background: rgba(255, 170, 0, 0.8);
		box-shadow: 0px 1px 15px 1px rgba(255, 170, 0, 0.9);
		padding-top: 10px;
		padding-bottom: 10px;
		border-radius: 3px;
		cursor: pointer;
		color: #ffffff;
	}
	
	
	.scaTitleBtn:hover {
		background: rgba(255, 170, 0, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(255, 170, 0, 0.9);
		transition: all 0.3s ease-in-out;
	}
	
	.scaCon1 {
		width: 1280px;
		margin: auto;
		height: 520px;
		overflow: hidden;
		padding-top: 40vh;
		padding-bottom: 100px;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.scaCon1Phone{
		display: none;
	}
	
	.scac1Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		letter-spacing: 0.5px;
		font-weight: 100;
	}
	
	.sca1Card1 {
		width: 350px;
		height: 350px;
		margin-top: 120px;
		display: inline-block;
		background: rgba(0, 0, 0, 0.7);
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 0.5);
		border-radius: 50%;
		z-index: 1;
		transition: all 0.3s ease;
	}
	
	
	.sca1Card1:hover {
		box-shadow: 0px 1px 50px 1px rgba(250, 118, 43, 1.0);
		transition: all 0.3s ease;
	}
	
	.sca1ImgBg {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 0.5);
		z-index: 2;
		position: relative;
		overflow: hidden;
		background: #000;
		top: -100px;
		left: 75px;
	}
	
	.sca1Imgs {
		width: 130px;
		height: 130px;
		margin: 35px;
		opacity: 0.8;
		/* border-radius: 50%; */
	}
	
	.sca1Text1 {
		color: #eaeaea;
		position: relative;
		top: -100px;
		font-size: 20px;
		padding: 30px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.2);
		font-family: T3;
	}
	
	.sca1Text2 {
		padding: 30px 55px 30px 55px;
		line-height: 28px;
		letter-spacing: 1px;
		font-size: 15px;
		color: #fff;
		position: relative;
		top: -100px;
		text-align: left;
		font-family: T1;
	}
	
	.scaVideo2 {
		width: 100%;
		position: absolute;
		z-index: -1;
		top: 40vh;
		opacity: 1;
		text-align: center;
	}
	
	.scaVideo2::before,
	.scaVideo2::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.scaVideo2::before {
		top: 0;
		left: 0;
		background: radial-gradient(circle, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaVideo2::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 50%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaCon2 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.scaCon2Phone{
		display: none;
	}
	
	.sca2Card1 {
		margin-top: 80px;
	}
	
	.sca2cImgBg {
		width: 100%;
		border-radius: 20px;
	}
	
	.sca2cImgBg::before,
	.sca2cImgBg::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.sca2cImgBg::before {
		top: 0;
		left: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sca2cImgBg::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 75%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.sca2cImg {
		width: 100%;
	}
	
	.sca2cView {
		width: 370px;
		display: inline-block;
	}
	
	.sca2cText1 {
		/* background: linear-gradient(90deg, #fff3d9 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent; */
		color: #fff;
		font-size: 20px;
		letter-spacing: 2px;
		margin-top: 120px;
		padding-bottom: 30px;
		font-family: DingTalkJinBuTi;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.3);
	
	}
	
	.sca2cText2 {
		margin-top: 30px;
		text-align: left;
		line-height: 30px;
		letter-spacing: 2px;
		font-size: 16px;
		color: #e8e8ee;
		font-family: T1;
		
	}
	
	.scaCon3 {
		width: 1280px;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
		padding-top: 100px;
		overflow: hidden;
		padding-bottom:50px
	}
	
	.scaCon3Phone {
		display: none;
	}
	
	.scaCardCon{
		width: 100%;
		position: absolute;
		z-index: -1;
	}
	
	.scaCardCon::before,
	.scaCardCon::after {
		content: "";
		position: absolute;
		width: 100%;
		top: 100px;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.scaCardCon::before {
		top: 100px;
		left: 0;
		background: linear-gradient(to right, rgba(0, 0, 0, 0.3) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaCardCon::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to left, rgba(0, 0, 0, 0.3) 95%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaCardImg{
		width: 100%;
		position: relative;
		top: 100px;
		opacity: 1;
	}
	
	.scac3Title {
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.sca3Div1{
		width: 340px;
		display: inline-block;
		position: relative;
		margin-top: 30px;
	}
	
	.sca3CardLeft{
		position: relative;
		left: 150px;
	}
	
	.sca3Div2{
		display: inline-flex;
		margin-top: 15px;
		position: relative;
	}
	
	.sca3Three{
		position: relative;
	}
	
	.sca3D2s{
		width: 340px;
		height: 340px;
		top: 0px;
		left: 150px;
		position: absolute;
	}
	
	.sca3D2c{
		width: 340px;
		height: 340px;
		top: 0px;
		position: absolute;
	}
	
	.sca3D2r{
		width: 340px;
		height: 340px;
		top: 0px;
		right: 150px;
		position: absolute;
	}
	
	.sca3Card {
		width: 0;
		height: 0;
		border-left: 170px solid transparent;
		border-right: 170px solid transparent;
		border-bottom: 340px solid rgba(255, 231, 197, 0.2);
		overflow: hidden;
		position: relative;
		top: -10px;
		display: inline-block;
	}
	
	.sca3Card:hover .sca3CardImg {
		transition: all 1s ease;
		transform: scale(1.2);
	}
	
	.sca3Card2 {
		width: 0;
		height: 0;
		border-left: 170px solid transparent;
		border-right: 170px solid transparent;
		border-bottom: 340px solid rgba(140, 129, 255, 0.2);
		overflow: hidden;
		position: relative;
		top: 5px;
		left: 150px;
		display: inline-block;
	}
	
	.sca3Card3 {
		width: 0;
		height: 0;
		border-left: 170px solid transparent;
		border-right: 170px solid transparent;
		border-top: 340px solid rgba(255, 196, 188, 0.2);
		overflow: hidden;
		top: -5px;
		position: relative;
		display: inline-block;
	}
	
	.sca3Card4 {
		width: 0;
		height: 0;
		border-left: 170px solid transparent;
		border-right: 170px solid transparent;
		border-bottom: 340px solid rgba(188, 143, 255, 0.2);
		overflow: hidden;
		position: relative;
		top: 5px;
		right: 150px;
		display: inline-block;
	}
	
	.sca3cTitle{
		position: absolute;
		top: 100px;
		left: 100px;
		padding-bottom: 20px;
		width: 140px;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1.5px;
		border-bottom: 1px solid rgba(85, 170, 255, 0.2);
		text-align: center;
		background: linear-gradient(90deg, #fffc9e 0%, #d5e8ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
	}
	
	
	.sca3cTitle2{
		position: absolute;
		top: 190px;
		left: 100px;
		padding-top: 20px;
		width: 140px;
		font-size: 18px;
		font-weight: bold;
		letter-spacing: 1.5px;
		border-top: 1px solid rgba(255, 170, 0, 0.2);
		text-align: center;
		background: linear-gradient(90deg, #fffc9e 0%, #d5e8ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
	}
	
	.sca3cImg{
		width: 100px;
		position: absolute;
		height: 100px;
		border-radius: 50%;
		top: 155px;
		left: 120px;
		overflow: hidden;
	}
	
	.sca3cImg2{
		width: 100px;
		position: absolute;
		height: 100px;
		border-radius: 50%;
		top: 75px;
		left: 120px;
		overflow: hidden;
	}
	
	.scaImgS{
		width: 100px;
		height: 100px;
		opacity: 0.6;
	}
	
	.sca3cText1{
		width: 270px;
		position: absolute;
		left: 35px;
		top: 270px;
		letter-spacing: 0.3px;
		line-height: 25px;
		background: linear-gradient(90deg, #fffc9e 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: T3;
	}
	
	.sca3cText2{
		width: 270px;
		position: absolute;
		left: 35px;
		top: 10px;
		letter-spacing: 0.3px;
		line-height: 25px;
		background: linear-gradient(90deg, #fffc9e 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: T3;
	}
	
	.scaCon4{
		margin-top: 50px;
	}
	
	.scac4Title{
		text-align: center;
		padding: 30px;
		font-size: 23px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4ConDiv{
		margin: 50px auto;
		width: 1280px;
		
	}
	
	.sca4ConFlex{
		/* display: inline-flex;
		align-items: center; */
		position: relative;
	}
	
	.sca4Left{
		width:800px;
		position: relative;
		z-index: 3;
		background: rgba(35, 39, 60, 1);
		box-shadow: 0px 0px 15px 3px rgba(85, 170, 255, 0.5);
		border-radius: 8px;
		overflow: hidden;
		transition: z-index 0.1s, opacity 1s;
	}
	
	.sca4Left:hover{
		z-index: 10;
	}
	
	.sca4Right{
		position: absolute;
		top: -50px;
		right: 0;
		width:520px;
		z-index: 2;
		border-radius: 8px;
		overflow: hidden;
		box-shadow: 0px 0px 15px 3px rgba(85, 170, 255, 0.5);
		transition: z-index 0.1s, opacity 0.5s;
		opacity: 0.7;
	}
	
	.sca4Right:hover{
		z-index: 10;
		opacity: 1;
	}
	
	.sca4Table{
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableEnd{
		display: flex;
		align-items: center;
	}
	
	.sca4Image{
		height: 35px;
		margin: 5px 0 5px 20px;
		border-radius: 3px;
	}
	
	.sca4ImageText{
		height: 35px;
		float: left;
		color: #ffffff;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 14px;
		margin: 10px 0px 10px 20px;
		letter-spacing: 0.1px;
		line-height: 35px;
		border-radius: 3px;
		background: rgba(85, 170, 255, 0.5);
		box-shadow: 0px 1px 20px 5px rgba(85, 170, 255, 0.3);
		font-family: T1;
	}
	
	.sca4T1{
		width: 150px;
		font-size: 18px;
		letter-spacing: 2px;
		background: linear-gradient(45deg, #8ccaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
		
	}
	
	.sca4T2{
		width: calc(100% - 150px);
		text-align: left;
		padding: 20px 0 20px 0;
		border-left: 1px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableLabel{
		display: flex;
		font-size: 12px;
		align-items: center;
		padding: 7px 0 7px 0;
		background: #0f111b;
		border-bottom: 2px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableItem:nth-child(1){
		width: 30%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TableItem:nth-child(2){
		width: 30%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TableItem:nth-child(3){
		width: 40%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TabCh{
		display: flex;
		align-items: center;
		background: #23273c;
		font-family: T1;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
	}
	
	.sca4TabChItem:nth-child(1){
		width: 30%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChItem:nth-child(2){
		width: 30%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChItem:nth-child(3){
		width: 40%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChLg:nth-child(1){
		padding: 12px 0 12px 0;
	}
	
	.sca4TabChLg:nth-child(2){
		padding: 0 0 12px 0;
	}
}

@media screen and (max-width: 769px) {
	.scaView {
		width: 100%;
		background: #000;
	}
	
	.scaVideo {
		width: 100%;
		position: absolute;
		z-index: 1;
		background: rgba(0, 0, 0, 1.0);
		text-align: center;
	}
	
	.scaVideo::before,
	.scaVideo::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		filter: blur(0.1px);
		/* 调整模糊程度 */
	}
	
	.scaVideo::before {
		top: 0;
		left: 0;
		background: linear-gradient(to top, rgba(0, 0, 0, 0.1) 99%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaVideo::after {
		bottom: 0;
		right: 0;
		background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 80%, rgba(0, 0, 0, 1.0) 100%);
		z-index: 1;
	}
	
	.scaVideoImg {
		padding: 50px;
		width: calc(100% - 100px);
		opacity: 0.2;
	}
	
	.scaContent {
		margin: auto;
		width: 100%;
		padding-top: 100px;
		padding-bottom: 100px;
		position: relative;
		z-index: 2;
		text-align: center;
	}
	
	.scaTitle {
		background: linear-gradient(180deg, #ffffff 0%, #ffe0aa 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-size: 20px;
		width: 100%;
		margin: auto;
		text-align: center;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
		letter-spacing: 1px;
		padding-bottom: 20px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.6);
	}
	
	.scaTitle2 {
		display: inline-block;
		background: linear-gradient(90deg, #ffe0aa 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		font-size: 20px;
		margin-top: 20px;
		font-size: 15px;
		letter-spacing: 2px;
	}
	
	.scaTitleBtn {
		width: 200px;
		margin: 20px auto;
		background: rgba(255, 170, 0, 0.8);
		box-shadow: 0px 1px 5px 1px rgba(255, 170, 0, 0.9);
		padding-top: 8px;
		padding-bottom: 8px;
		font-size: 15px;
		border-radius: 3px;
		cursor: pointer;
		color: #ffffff;
	}
	
	
	.scaTitleBtn:hover {
		background: rgba(255, 170, 0, 1.0);
		box-shadow: 0px 1px 30px 1px rgba(255, 170, 0, 0.9);
		transition: all 0.3s ease-in-out;
	}
	
	.scaCon1 {
		display: none;
	}
	
	.scaCon1Phone{
		width: 100%;
		margin: auto;
		overflow: hidden;
		padding-top: 100px;
		padding-bottom: 100px;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.scac1Title {
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		font-family: DingTalkJinBuTi;
		letter-spacing: 0.5px;
		font-weight: 100;
	}
	
	.sca1Card1 {
		width: 350px;
		height: 350px;
		margin-top: 120px;
		display: inline-block;
		background: rgba(0, 0, 0, 0.7);
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 0.5);
		border-radius: 50%;
		z-index: 1;
		transition: all 0.3s ease;
	}
	
	
	.sca1Card1:hover {
		box-shadow: 0px 1px 50px 1px rgba(250, 118, 43, 1.0);
		transition: all 0.3s ease;
	}
	
	.sca1ImgBg {
		width: 200px;
		height: 200px;
		border-radius: 50%;
		box-shadow: 0px 1px 15px 1px rgba(250, 118, 43, 0.5);
		z-index: 2;
		position: relative;
		overflow: hidden;
		background: #000;
		top: -100px;
		left: 75px;
	}
	
	.sca1Imgs {
		width: 130px;
		height: 130px;
		margin: 35px;
		opacity: 0.8;
		/* border-radius: 50%; */
	}
	
	.sca1Text1 {
		color: #eaeaea;
		position: relative;
		top: -100px;
		font-size: 16px;
		padding: 30px;
		border-bottom: 0.3px solid rgba(255, 255, 255, 0.2);
		font-family: T3;
	}
	
	.sca1Text2 {
		padding: 30px 55px 30px 55px;
		line-height: 28px;
		letter-spacing: 1px;
		font-size: 15px;
		color: #fff;
		position: relative;
		top: -100px;
		text-align: left;
		font-family: T1;
	}
	
	.scaVideo2 {
		display: none;
	}
	
	.scaCon2 {
		display: none;
	}
	
	.scaCon2Phone{
		width: 100%;
		margin: auto;
		border-radius: 3px;
		position: relative;
		text-align: center;
	}
	
	.sca2cView {
		width: 90%;
		display: inline-block;
	}
	
	.sca2cText1 {
		/* background: linear-gradient(90deg, #fff3d9 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent; */
		color: #fff;
		font-size: 16px;
		letter-spacing: 2px;
		margin-top: 80px;
		padding-top: 30px;
		font-family: DingTalkJinBuTi;
		border-top: 0.3px solid rgba(255, 255, 255, 0.3);
	
	}
	
	.sca2cText2 {
		margin-top: 30px;
		text-align: left;
		line-height: 30px;
		letter-spacing: 2px;
		font-size: 14px;
		color: #e8e8ee;
		font-family: T1;
		
	}
	
	.scaCon3 {
		display: none;
	}
	
	.scaCon3Phone {
		width: 100%;
		text-align: center;
		padding-top: 30px;
		border-top: 0.3px solid rgba(255, 255, 255, 0.3);
		margin-top: 50px;
	}
	
	.sca3Div1{
		width: 90%;
		padding-top: 30px;
		display: inline-block;
		margin-bottom: 50px;
		box-shadow: 0px 1px 15px 1px rgba(255, 255, 255, 0.9);
	}
	
	
	.scac3Title {
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		margin-bottom: 30px;
		font-family: DingTalkJinBuTi;
	}

	.sca3cTitle{
		font-size: 16px;
		font-weight: bold;
		letter-spacing: 1.5px;
		text-align: center;
		background: linear-gradient(90deg, #fffc9e 0%, #d5e8ff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
	}
	
	.sca3cImg{
		width: 100%;
		height: 100px;
		text-align: center;
		border-radius: 50%;
	}
	
	.scaImgS{
		width: 100px;
		height: 100px;
		opacity: 0.6;
	}
	
	.sca3cText1{
		width: 80%;
		display: inline-block;
		text-align: left;
		letter-spacing: 0.3px;
		line-height: 25px;
		background: linear-gradient(90deg, #fffc9e 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: T3;
		margin-bottom: 50px;
	}

	.scaCon4{
		margin-top: 50px;
	}
	
	.scac4Title{
		text-align: center;
		padding: 30px;
		font-size: 18px;
		color: #ffffff;
		letter-spacing: 0.5px;
		font-weight: 100;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4ConDiv{
		margin: 10px auto;
		width: 100%;
		
	}
	
	.sca4ConFlex{
		/* display: inline-flex;
		align-items: center; */
		position: relative;
	}
	
	.sca4Left{
		width:90%;
		margin: auto;
		position: relative;
		z-index: 3;
		background: rgba(35, 39, 60, 1);
		box-shadow: 0px 0px 15px 3px rgba(85, 170, 255, 0.5);
		border-radius: 8px;
		overflow: hidden;
		transition: z-index 0.1s, opacity 1s;
	}
	
	.sca4Left:hover{
		z-index: 10;
	}
	
	.sca4Right{
		width:90%;
		display: inline-block;
		margin-top: 50px;
		z-index: 2;
		border-radius: 8px;
		overflow: hidden;
		box-shadow: 0px 0px 15px 3px rgba(85, 170, 255, 0.5);
		transition: z-index 0.1s, opacity 0.5s;
		opacity: 1;
	}
	
	.sca4Right:hover{
		z-index: 10;
		opacity: 1;
	}
	
	.sca4Table{
		display: flex;
		align-items: center;
		border-bottom: 2px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableEnd{
		display: flex;
		align-items: center;
	}
	
	.sca4Image{
		height: 35px;
		margin: 5px 0 5px 20px;
		border-radius: 3px;
	}
	
	.sca4ImageText{
		height: 35px;
		float: left;
		color: #ffffff;
		padding-left: 15px;
		padding-right: 15px;
		font-size: 14px;
		margin: 10px 0px 10px 20px;
		letter-spacing: 0.1px;
		line-height: 35px;
		border-radius: 3px;
		background: rgba(85, 170, 255, 0.5);
		box-shadow: 0px 1px 20px 5px rgba(85, 170, 255, 0.3);
		font-family: T1;
	}
	
	.sca4T1{
		width: 150px;
		font-size: 16px;
		letter-spacing: 2px;
		background: linear-gradient(45deg, #8ccaff 0%, #ffffff 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: DingTalkJinBuTi;
		
	}
	
	.sca4T2{
		width: calc(100% - 150px);
		text-align: left;
		padding: 20px 0 20px 0;
		border-left: 1px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableLabel{
		display: flex;
		font-size: 12px;
		align-items: center;
		padding: 7px 0 7px 0;
		background: #0f111b;
		border-bottom: 2px solid rgba(85, 170, 255, 0.2);
	}
	
	.sca4TableItem:nth-child(1){
		width: 30%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TableItem:nth-child(2){
		width: 30%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TableItem:nth-child(3){
		width: 40%;
		color: #ffffff;
		padding: 12px 0 12px 0;
		font-family: DingTalkJinBuTi;
	}
	
	.sca4TabCh{
		display: flex;
		align-items: center;
		background: #23273c;
		font-family: T1;
		border-bottom: 1px solid rgba(85, 170, 255, 0.3);
	}
	
	.sca4TabChItem:nth-child(1){
		width: 30%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChItem:nth-child(2){
		width: 30%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChItem:nth-child(3){
		width: 40%;
		color: #ffffff;
		font-size: 12px;
	}
	
	.sca4TabChLg:nth-child(1){
		padding: 12px 0 12px 0;
	}
	
	.sca4TabChLg:nth-child(2){
		padding: 0 0 12px 0;
	}
}